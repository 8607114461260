<template>
<div>
    <div class="col-md-12 flex-between-row">
        <div class="col-md-6">
            <h3 class="page-header"><i class="fa fa fa-user-md animated bounceInDown show-info"></i>Dispatched/Rejected Orders</h3>
        </div>
        <div class="col-md-6 text-r">
            <button class="btn btn-primary" @click="refresh()"><i class="fa fa-refresh"></i> Referesh</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel panel-cascade">

                <div class="panel-body ">             
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-horizontal cascde-forms">

                                <div class="form-group flex-row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" />
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Address</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" />
                                    </div>
                                </div> -->
                                
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Entry Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="entrydatefrom" type="date"  />
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Dispatched Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="disposedatefrom" type="date" />
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Schedule Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="scheduledatefrom" type="text" />
                                    </div>
                                </div> -->
                                <div class="form-group flex-row" style="display:none">
                                    <label class="col-lg-4 col-md-3 control-label">Status</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="isdispose"><option value="0">SELECT</option>
                                            <option value="0">PENDING</option>
                                            <option value="1">DISPOSE</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row" v-if="loggedinuser?.role<2">
                                    <label class="col-lg-4 col-md-3 control-label">Followed By 12</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select v-model="followbyid" class="form-control form-cascade-control input-small">
                                            <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                                        </select>             
                                    </div>
                                </div> -->
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Order No.</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="complaintno" type="number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 CHK">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group flex-row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customermobile" type="number"/>
                                    </div>
                                </div>
                               
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Entry Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="entrydateto" type="date" />
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Dispatched Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="disposedateto" type="date" />
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Schedule Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="scheduledateto" type="text" value="" />
                                    </div>
                                </div> -->
                                
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Entry By</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select v-model="entrybyid" class="form-control form-cascade-control input-small">
                                            <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Status</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select v-model="status" class="form-control form-cascade-control input-small">
                                            <option value="0">Pending Orders</option>
                                            <option value="1">Approved Orders</option>
                                            <option value="2">Rejected Orders</option>
                                            <option value="3">Order Dispatch</option>
                                            <option value="4">On the Way</option>
                                            <option value="5">Order Delivered</option>
                                            <option value="6">Order Rejected</option>
                                        </select>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group flex-row">
                            <div class="col-md-9" style="">
                                <input type="submit" @click="search()" value="Search" class="btn bg-primary text-white btn-md">
                            </div>
                            <div class="col-md-3" style="">
                                <input type="submit" @click="initial()" value="Clear" class="btn bg-danger text-white btn-md">
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <div class="panel">
            <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                <table class="table users-table table-condensed table-hover table-bordered table-sm">
                    <thead class="table-font" style="font-size:13px;">
                        <tr>
                            <th>#</th>
                            <th>Vsdigi Id</th>
                            <th class="visible-lg">NAME</th>
                            <th>MOBILE</th>
                            <th>ADDRESS</th>
                            <th>Order NO.</th>
                            <th>Product</th>
                            <th>Order Remark</th>
                            <th>Dispatch Remark</th>
                            <th>Referal By</th>
                            <!-- <th>FALLOW BY</th> -->
                            <th>Dispatched BY<br>Dispatched DATE</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                   <dispatched-order-row v-for="(item,index) in leads" :key="item.id" :item="item" :index="index"></dispatched-order-row>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DispatchedOrderRow from './DispatchedOrderRow.vue';
export default {
    components: {  DispatchedOrderRow },
    data() {
        return {
            // leads:[],
            customername:'',
            customermobile:'',
            followbyid:0,
            entrydatefrom:'',
            disposedatefrom:'',
            requesttype:4,
            scheduledatefrom:'',
            entrybyid:0,
            disposedateto:'',
            entrydateto:'',
            scheduledateto:'',
            leadtype:0,
            isdispose:1,
            complaintno:'',
            status:''
        }
    },
    mounted() {
        this.refresh();
    },
    computed:{
        ...mapGetters(['employees','leads','loggedinuser'])
    },
    methods: {
        refresh(){
            this.$store.dispatch("fetchemployees")
            this.$store.dispatch("fetchattributes")
            let param = {isdispose:1,requesttype:this.requesttype}
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/leads/fetch/orders',param)
            .then((response) => this.processLeadResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processLeadResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.$store.commit("assignleads",data)
        },
        search(){
            let param = {isdispose:1,
                customername:this.customername,
                customermobile:this.customermobile,
                followbyid:this.followbyid,
                entrydatefrom:this.entrydatefrom,
                disposedatefrom:this.disposedatefrom,
                requesttype:this.requesttype,
                scheduledatefrom:this.scheduledatefrom,
                entrybyid:this.entrybyid,
                disposedateto:this.disposedateto,
                entrydateto:this.entrydateto,
                scheduledateto:this.scheduledateto,
                complaintno:this.complaintno,
                status:this.status,
            }
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/leads/fetchrecords',param)
            .then((response) => this.processResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.$store.commit("assignleads",data.lead)
            // this.rows=data.count
        },
        initial(){
            this.customername=''
            this.customermobile=''
            this.followbyid=0,
            this.entrydatefrom=''
            this.disposedatefrom=''
            this.scheduledatefrom=''
            this.entrybyid=0
            this.disposedateto=''
            this.entrydateto=''
            this.scheduledateto=''
            this.leadtype=0
            this.isdispose=0
            this.complaintno=''
            this.status=''
        }
    },
}
</script>
<style>
.flex-row{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>